<template>
  <form>
    <!-- Search Bar for Address -->
    <div class="search-bar">
      <input
        type="text"
        v-model="searchQuery"
        :placeholder="translations.search_address || 'Search Address'"
      />
      <button @click.prevent="searchAddress">
        <font-awesome-icon icon="search" />
      </button>
    </div>
    <!-- Map Container -->
    <div id="map" class="map-container"></div>
    <!-- Points of Interest (POIs) Form -->
    <div v-for="(poi, index) in pois" :key="index" class="form-group">
      <!-- POI Name Input -->
      <input
        type="text"
        v-model="poi.name"
        :placeholder="translations.poi_name || 'POI Name'"
      />
      <!-- Subcategories Selection -->
      <select v-model="poi.sub_categories" multiple>
        <optgroup
          v-for="category in categories"
          :key="category.id"
          :label="category.name"
        >
          <option
            v-for="subCategory in category.subCategories"
            :key="subCategory.id"
            :value="subCategory.id"
          >
            {{ subCategory.name }}
          </option>
        </optgroup>
      </select>
      <!-- Swipeable Cards for Layout Selection -->
      <SwipeableCards @layoutSelected="setSelectedCard(index, $event)" />
      <!-- Content Blocks for Each Language -->
      <div v-for="language in selectedLanguages" :key="language">
        <h3>
          {{ translations.fill_card_for || "Fill Card For" }}
          {{ languageMap[language] }}
        </h3>
        <div
          v-for="field in getLayoutFields(poi.selectedCard)"
          :key="field.id"
          class="form-group"
        >
          <!-- Field Type Label -->
          <label>{{ field.type }}</label>
          <!-- Rich Text Input -->
          <input
            v-if="field.type === 'richtext'"
            type="text"
            v-model="poi.contentBlock[`${language}_${field.id}`]"
            :placeholder="field.placeholder"
          />
          <!-- Image Upload Input -->
          <div v-else-if="field.type === 'image'" class="file-input-wrapper">
            <font-awesome-icon
              icon="camera"
              class="file-input-icon"
              @click="triggerFileInput($event, index)"
            />
            <input
              ref="fileInputs"
              type="file"
              class="d-none"
              @change="onFileChange($event, poi, `${language}_${field.id}`)"
            />
          </div>
          <!-- Audio Upload Input -->
          <div v-else-if="field.type === 'audio'" class="file-input-wrapper">
            <font-awesome-icon
              icon="microphone"
              class="file-input-icon"
              @click="triggerFileInput($event, index)"
            />
            <input
              ref="fileInputs"
              type="file"
              class="d-none"
              @change="onFileChange($event, poi, `${language}_${field.id}`)"
            />
            <!-- Recording Controls -->
            <div v-if="recordingField === `${language}_${field.id}`">
              <button @click.prevent="stopRecording">
                {{ translations.stop_recording || "Stop Recording" }}
              </button>
            </div>
            <div v-else>
              <button
                @click.prevent="startRecording(`${language}_${field.id}`)"
              >
                {{ translations.start_recording || "Start Recording" }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Remove POI Button -->
      <button @click.prevent="removePoi(index)" class="btn-remove">
        {{ translations.remove || "Remove" }}
      </button>
    </div>
    <!-- Add POI Button -->
    <button @click.prevent="addPoi" class="btn-submit">
      {{ translations.add_poi || "Add POI" }}
    </button>
  </form>
</template>

<script>
import { useMainStore } from "@/stores/mainStore";
import SwipeableCards from "@/components/SwipeableCards.vue";
import { s3Upload } from "@/utils/fileUpload";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faSearch,
  faCamera,
  faImage,
  faMicrophone,
  faQuestion,
} from "@fortawesome/free-solid-svg-icons";
import mapMixin from "@/mixins/map";
import categoriesMixin from "@/mixins/categories";
import layoutsMixin from "@/mixins/layouts";
import L from "leaflet";
import axios from "axios";

// Add font-awesome icons to the library
library.add(faSearch, faCamera, faImage, faMicrophone, faQuestion);

export default {
  mixins: [mapMixin, categoriesMixin, layoutsMixin],
  components: { SwipeableCards, FontAwesomeIcon },
  setup() {
    const mainStore = useMainStore();
    return { mainStore };
  },
  data() {
    return {
      searchQuery: "", // Search query for address
      recordingField: null, // Field ID for recording audio
      searchMarker: null, // Marker for searched address
      mediaRecorder: null, // Media recorder for audio
      audioChunks: [], // Chunks of recorded audio
    };
  },
  computed: {
    pois() {
      return this.mainStore.pois; // Points of Interest (POIs)
    },
    categories() {
      return this.mainStore.categories; // Categories for POIs
    },
    translations() {
      return this.mainStore.translations; // Translations for text
    },
    layouts() {
      return this.mainStore.layouts; // Layouts for display cards
    },
    selectedLanguages() {
      return this.mainStore.selectedLanguages.length
        ? this.mainStore.selectedLanguages
        : [this.mainStore.currentLanguage]; // Selected languages for content
    },
    languageMap() {
      return this.mainStore.languageMap; // Language map for display
    },
  },
  mounted() {
    this.initializeMap(); // Initialize the map when component is mounted
  },
  methods: {
    // Get fields for the selected layout
    getLayoutFields(selectedCard) {
      const layout = this.layouts.find((layout) => layout.id === selectedCard);
      return layout ? layout.fields : [];
    },
    // Set the selected card layout
    setSelectedCard(index, layout) {
      this.pois[index].selectedCard = layout.id;
      this.pois[index].contentBlock = {};
    },
    // Handle file input change event
    async onFileChange(event, poi, fieldId) {
      const file = event.target.files[0];
      if (file) {
        try {
          const uploadID = await s3Upload(file);
          poi.contentBlock[fieldId] = uploadID;
        } catch (error) {
          console.error("Error uploading file:", error);
        }
      }
    },
    // Trigger file input click
    triggerFileInput(event, index) {
      const fileInput = this.$refs.fileInputs[index];
      if (fileInput) {
        fileInput.click();
      }
    },
    // Handle audio recording stop event
    async onRecordingStop(poi, fieldId, audioBlob) {
      const file = new File([audioBlob], `audio_${fieldId}.wav`, {
        type: "audio/wav",
      });
      try {
        const uploadID = await s3Upload(file);
        poi.contentBlock[fieldId] = uploadID;
      } catch (error) {
        console.error("Error uploading audio file:", error);
      }
    },
    // Start audio recording
    startRecording(fieldId) {
      if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
        alert(
          this.translations.error_recording_audio || "Error recording audio"
        );
        return;
      }

      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          this.mediaRecorder = new MediaRecorder(stream);
          this.mediaRecorder.start();
          this.audioChunks = [];

          this.mediaRecorder.addEventListener("dataavailable", (event) => {
            this.audioChunks.push(event.data);
          });

          this.mediaRecorder.addEventListener("stop", () => {
            const audioBlob = new Blob(this.audioChunks);
            this.onRecordingStop(this.pois[0], fieldId, audioBlob);
          });

          this.recordingField = fieldId;
        })
        .catch((error) => {
          console.error("Error accessing media devices: ", error);
          alert(
            this.translations.error_recording_audio || "Error recording audio"
          );
        });
    },
    // Stop audio recording
    stopRecording() {
      if (this.mediaRecorder) {
        this.mediaRecorder.stop();
        this.recordingField = null;
      }
    },
    // Add a new POI
    addPoi() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.mainStore.pois.push({
              map_content: false,
              name: "",
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
              sub_categories: [],
              content_block: [],
              selectedCard: null,
              contentBlock: {
                oneRT: "",
                twoRT1: "",
                twoRT2: "",
              },
            });
          },
          (error) => {
            console.error("Error getting location: ", error);
            this.mainStore.pois.push({
              map_content: false,
              name: "",
              latitude: 44.647067,
              longitude: -63.575619,
              sub_categories: [],
              content_block: [],
              selectedCard: null,
              contentBlock: {
                oneRT: "",
                twoRT1: "",
                twoRT2: "",
              },
            });
            alert(
              this.translations.manual_location_selection ||
                "Please select the location manually."
            );
          }
        );
      } else {
        this.mainStore.pois.push({
          map_content: false,
          name: "",
          latitude: 44.647067,
          longitude: -63.575619,
          sub_categories: [],
          content_block: [],
          selectedCard: null,
          contentBlock: {
            oneRT: "",
            twoRT1: "",
            twoRT2: "",
          },
        });
        alert(
          this.translations.manual_location_selection ||
            "Please select the location manually."
        );
      }
    },
    // Remove a POI by index
    removePoi(index) {
      this.mainStore.pois.splice(index, 1);
    },
    // Initialize the map
    initializeMap() {
      if (!this.mainStore.map) {
        this.mainStore.map = L.map("map").setView([44.647067, -63.575619], 13);
        L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
          attribution: "© OpenStreetMap contributors",
        }).addTo(this.mainStore.map);
      }
      this.updateMarkers();
    },
    // Update markers on the map
    updateMarkers() {
      if (!this.mainStore.map) {
        return;
      }
      this.mainStore.markers.forEach((marker) =>
        this.mainStore.map.removeLayer(marker)
      );
      this.mainStore.markers = [];

      this.pois.forEach((poi) => {
        if (poi.latitude && poi.longitude) {
          const marker = L.marker([poi.latitude, poi.longitude], {
            draggable: true,
          }).addTo(this.mainStore.map);
          marker.on("dragend", (event) => {
            const { lat, lng } = event.target.getLatLng();
            poi.latitude = lat;
            poi.longitude = lng;
          });
          this.mainStore.markers.push(marker);
        }
      });
    },
    // Search for an address and update the map view
    async searchAddress() {
      try {
        const response = await axios.get(
          `https://nominatim.openstreetmap.org/search?format=json&q=${this.searchQuery}`
        );
        if (response.data.length > 0) {
          const { lat, lon } = response.data[0];
          console.log(`Latitude: ${lat}, Longitude: ${lon}`);
          this.mainStore.map.setView([lat, lon], 13);
          this.updateSearchMarker(parseFloat(lat), parseFloat(lon));

          // Verify the geometry data format
          const geometry = {
            type: "Point",
            coordinates: [parseFloat(lon), parseFloat(lat)],
          };
          console.log("Geometry data: ", JSON.stringify(geometry));
        } else {
          alert(this.translations.address_not_found || "Address not found");
        }
      } catch (error) {
        console.error("Error searching address:", error);
      }
    },
    // Update the search marker on the map
    updateSearchMarker(lat, lng) {
      if (this.searchMarker) {
        this.searchMarker.setLatLng([lat, lng]);
      } else {
        this.searchMarker = L.marker([lat, lng], {
          draggable: true,
        }).addTo(this.mainStore.map);
        this.searchMarker.on("dragend", (event) => {
          const { lat, lng } = event.target.getLatLng();
          this.mainStore.pois[0].latitude = lat;
          this.mainStore.pois[0].longitude = lng;
        });
      }
      this.mainStore.pois[0].latitude = lat;
      this.mainStore.pois[0].longitude = lng;
    },
  },
  watch: {
    pois: {
      handler() {
        this.updateMarkers();
      },
      deep: true,
    },
  },
};
</script>

<style>
@import "@/assets/style.css";
</style>
