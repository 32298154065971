<template>
    <div class="logout-page">
      <h2>{{ translations.logging_out || "Logging Out..." }}</h2>
    </div>
  </template>
  
  <script>
  import { useMainStore } from "@/stores/mainStore";
  import api from "@/endpoints/api";
  
  export default {
    setup() {
      const mainStore = useMainStore();
      return { mainStore };
    },
    computed: {
      translations() {
        return this.mainStore.translations;
      },
      token() {
        return localStorage.getItem('authToken');
      },
      userEmail() {
        return this.mainStore.userEmail;
      },
      orgDomainName() {
        return this.mainStore.orgUrl;
      }
    },
    async mounted() {
      try {
        await api.post('/api/user/logout/', {}, {
          headers: {
            'Authorization': `Token ${this.token}`
          }
        });
        this.mainStore.logout();
        alert(this.translations.logout_success || 'Logout successful!');
        this.$router.push({ name: 'landing-page' });
      } catch (error) {
        console.error('Failed to logout:', error);
        if (error.response) {
          console.error('Error data:', error.response.data);
          console.error('Error status:', error.response.status);
          console.error('Error headers:', error.response.headers);
        } else if (error.request) {
          console.error('No response received:', error.request);
        } else {
          console.error('Error message:', error.message);
        }
        alert(this.translations.logout_failed || 'Failed to logout. Check the console for more details.');
        this.$router.push({ name: 'landing-page' });
      }
    }
  };
  </script>
  
  <style scoped>
  .logout-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .logout-page h2 {
    margin-bottom: 20px;
  }
  </style>
  