import { defineStore } from 'pinia';
import { loadLanguageFile } from '@/localization';

// Define the main store
export const useMainStore = defineStore('main', {
  state: () => ({
    // User information
    userName: "",
    userEmail: "",
    
    // Application settings
    currentLanguage: localStorage.getItem('userLanguage') || 'en',
    orgUrl: "api.lv.com",
    
    // Payload structure
    payload: {
      org_domain_name: "api.lv.com",
      org_key: "abcd",
      user_supplied_name: "name",
      id_field: 0,
      pass_key: null,
      user_supplied_email: "user@example.com",
      map_json: {
        is_public: true,
        languages: [],
        tags: [],
        pois: []
      }
    },
    
    // Language settings
    availableLanguages: ["en", "fr", "de", "ar"],
    selectedLanguages: [],
    languageMap: {
      en: "English",
      fr: "French",
      de: "German",
      ar: "Arabic",
    },
    
    // Tags, POIs, and categories
    tags: [""],
    map: null,
    markers: [],
    pois: [],
    categories: [],
    
    // Translations and other data
    translations: {},
    postableData: null,
    payloadLanguages: [],
    subCategoryMap: {},
    categoryMap: {},
    layouts: [],
    selectedLayout: null,
    maps: [],
    
    // Authentication status
    isLoggedIn: !!localStorage.getItem('authToken'),
  }),
  
  actions: {
    // Update the user name
    updateUserName(name) {
      this.userName = name.trim();
      this.payload.user_supplied_name = name.trim() || this.payload.user_supplied_name;
    },
    
    // Update the user email
    updateUserEmail(email) {
      this.userEmail = email.trim();
      this.payload.user_supplied_email = email.trim() || this.payload.user_supplied_email;
    },
    
    // Set the current language and load translations
    async setCurrentLanguage(language) {
      this.currentLanguage = language;
      const translations = await loadLanguageFile(language);
      this.updateTranslations(translations);
      localStorage.setItem('userLanguage', language);
    },
    
    // Update translations
    updateTranslations(translations) {
      this.translations = translations;
    },
    
    // Update selected languages
    updateSelectedLanguages(languages) {
      this.selectedLanguages = languages;
      this.payload.map_json.languages = languages;
    },
    
    // Add a new tag
    addTag() {
      this.tags.push("");
    },
    
    // Remove a tag by index
    removeTag(index) {
      if (this.tags.length > 1) {
        this.tags.splice(index, 1);
      }
    },
    
    // Update tags
    updateTags(tags) {
      this.tags = tags;
    },
    
    // Update POIs
    updatePois(pois) {
      this.pois = pois;
    },
    
    // Update payload languages
    updatePayloadLanguages(languages) {
      this.payloadLanguages = languages;
    },
    
    // Update postable data
    updatePostableData(data) {
      this.postableData = data;
      this.payload.map_json = data;
    },
    
    // Set the ID field
    setIdField(id) {
      this.payload.id_field = id;
    },
    
    // Set categories
    setCategories(categories) {
      this.categories = categories;
    },
    
    // Set sub-category map
    setSubCategoryMap(subCategoryMap) {
      this.subCategoryMap = subCategoryMap;
    },
    
    // Set category map
    setCategoryMap(categoryMap) {
      this.categoryMap = categoryMap;
    },
    
    // Set layouts
    setLayouts(layouts) {
      this.layouts = layouts;
    },
    
    // Update selected layout
    updateSelectedLayout(layout) {
      this.selectedLayout = layout;
    },
    
    // Set maps
    setMaps(maps) {
      this.maps = maps;
    },
    
    // Handle user login
    login(token) {
      localStorage.setItem('authToken', token);
      this.isLoggedIn = true;
    },
    
    // Handle user logout
    logout() {
      localStorage.removeItem('authToken');
      this.isLoggedIn = false;
    }
  }
});
