import api from "@/endpoints/api";
import { useMainStore } from "@/stores/mainStore";

export default {
  setup() {
    const mainStore = useMainStore();
    return { mainStore };
  },
  async created() {
    await this.fetchLayouts();
  },
  methods: {
    async fetchLayouts() {
      const endpoint = `/api/layouts/`;
      try {
        const minResponse = await api.get(endpoint, {
          params: {
            org_domain_name: this.mainStore.orgUrl,
            min: true
          }
        });

        const fullResponse = await api.get(endpoint, {
          params: {
            org_domain_name: this.mainStore.orgUrl
          }
        });

        if (minResponse.data && typeof minResponse.data.layouts === 'object' && fullResponse.data && Array.isArray(fullResponse.data)) {
          const minLayoutsMap = minResponse.data.layouts;

          const layouts = fullResponse.data.map(layout => {
            const minLayoutId = minLayoutsMap[layout.card_name];
            return {
              id: minLayoutId, 
              name: layout.card_name,
              fields: Object.keys(layout.layout_field).map(key => ({
                id: key,
                type: layout.layout_field[key][0],
                placeholder: `${layout.layout_field[key][0]} field`
              }))
            };
          });

          this.mainStore.setLayouts(layouts);
        } else {
          console.error("Layouts format is incorrect:", minResponse.data, fullResponse.data);
          alert("Layouts data is not in the expected format.");
        }
      } catch (error) {
        console.error("Failed to fetch layouts:", error);
        alert("Failed to fetch layouts.");
      }
    },
  }
};
