<template>
    <div class="landing-page">
      <div id="map"></div>
      <div class="overlay">
        <h1>Tourism Guide</h1>
        <button @click="showLanguageSelection">Click to Continue</button>
      </div>
    </div>
  </template>
  
  <script>
  import L from 'leaflet'; 
  
  export default {
    methods: {
      showLanguageSelection() {
        this.$router.push({ name: 'language-selection' });
      },
      initMap() {
        const map = L.map('map').setView([45.0918, -64.3633], 13); // Set view to Wolfville
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        }).addTo(map);
      },
    },
    mounted() {
      this.initMap();
    },
  };
  </script>
  
  <style scoped>
  .landing-page {
    position: relative;
    height: 100vh;
    width: 100%;
  }
  
  #map {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    text-align: center;
    z-index: 2;
  }
  
  .overlay h1 {
    font-size: 3rem;
    margin-bottom: 20px;
  }
  
  .overlay button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 1.5rem;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .overlay button:hover {
    background-color: #45a049;
  }
  </style>
  