<template>
  <div id="app">
    <header v-if="showHeader">
      <nav>
        <button v-if="!isLoggedIn" @click="goToLogin">
          {{ translations.login || "Login" }}
        </button>
        <button v-if="!isLoggedIn" @click="goToRegister">
          {{ translations.sign_up || "Sign Up" }}
        </button>
        <button v-if="isLoggedIn" @click="goToLogout">
          {{ translations.logout || "Logout" }}
        </button>
      </nav>
    </header>
    <router-view />
  </div>
</template>

<script>
import { useMainStore } from "@/stores/mainStore";
import { computed } from "vue";
import { useRoute } from "vue-router";

export default {
  setup() {
    const mainStore = useMainStore();
    const route = useRoute();

    const showHeader = computed(() => {
      const noHeaderRoutes = [
        "language-selection",
        "auth-selection",
        "login-page",
        "register-page",
        "logout-page",
      ];
      return !noHeaderRoutes.includes(route.name);
    });

    return { mainStore, showHeader };
  },
  computed: {
    isLoggedIn() {
      return this.mainStore.isLoggedIn;
    },
    translations() {
      return this.mainStore.translations;
    },
  },
  methods: {
    goToLogin() {
      this.$router.push({ name: "login-page" });
    },
    goToRegister() {
      this.$router.push({ name: "register-page" });
    },
    goToLogout() {
      this.$router.push({ name: "logout-page" });
    },
  },
};
</script>

<style scoped>
header {
  position: fixed;
  top: 0;
  right: 0;
  padding: 10px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

nav {
  display: flex;
  gap: 10px;
}

button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 5px;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #45a049;
}
</style>
