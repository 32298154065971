<template>
  <div class="auth-selection-page">
    <h2>{{ translations.choose_option || "Choose an Option:" }}</h2>
    <div class="options">
      <button @click="signUp">{{ translations.sign_up || "Sign Up" }}</button>
      <button @click="login">{{ translations.login || "Login" }}</button>
      <button @click="continueAsGuest">{{ translations.continue_as_guest || "Continue as Guest" }}</button>
    </div>
  </div>
</template>

<script>
import { useMainStore } from "@/stores/mainStore";

export default {
  setup() {
    const mainStore = useMainStore();
    return { mainStore };
  },
  computed: {
    // Computed property to get translations from the store
    translations() {
      return this.mainStore.translations;
    },
  },
  methods: {
    // Method to navigate to the registration page
    signUp() {
      this.$router.push({ name: 'register-page' });
    },
    // Method to navigate to the login page
    login() {
      this.$router.push({ name: 'login-page' });
    },
    // Method to continue as a guest and navigate to the main app
    continueAsGuest() {
      this.$router.push({ name: 'main-app' });
    },
  },
};
</script>

<style scoped>
.auth-selection-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.auth-selection-page h2 {
  margin-bottom: 20px;
}

.options {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.options button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  margin: 10px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.options button:hover {
  background-color: #45a049;
}
</style>
