import axios from "axios";

// Create an Axios instance with the base URL and headers configured
const api = axios.create({
  baseURL: "https://dev.strollopia.com/",
  headers: {
    "Content-Type": "application/json"
  }
});

export default api;
