import api from "@/endpoints/api";
import axios from "axios";

// Function to upload a file to S3 and process it
export async function s3Upload(file) {
    try {
        // Request a signed upload URL from the API
        const { data } = await api.get("/api/media/signed-url/", {
            params: { filename: file.name }
        });

        // Upload the file to the signed URL
        const options = {
            headers: {
                "Content-Type": file.type,
            }
        };
        const publicUploadID = data.publicUploadID;

        await axios.put(data.uploadURL, file, options);

        // Process the uploaded file
        try {
            await api.put(`/api/media/public-upload/${publicUploadID}/process/`, { publicUploadID });
        } catch (err) {
            console.error("Error processing file: ", err);
        }

        return publicUploadID;
    } catch (err) {
        console.error("Error uploading file: ", err);
        throw err;
    }
}
