<template>
    <div class="register-page">
      <h2>{{ translations.sign_up || "Sign Up" }}</h2>
      <form @submit.prevent="register">
        <div>
          <label for="name">{{ translations.name || "Name" }}:</label>
          <input type="text" id="name" v-model="mainStore.userName" required />
        </div>
        <div>
          <label for="email">{{ translations.email || "Email" }}:</label>
          <input type="email" id="email" v-model="mainStore.userEmail" required />
        </div>
        <div>
          <label for="password">{{ translations.password || "Password" }}:</label>
          <input type="password" id="password" v-model="password" required />
        </div>
        <button type="submit">{{ translations.register || "Register" }}</button>
      </form>
    </div>
  </template>
  
  <script>
  import { useMainStore } from "@/stores/mainStore";
  import api from "@/endpoints/api";
  
  export default {
    data() {
      return {
        password: ''
      };
    },
    setup() {
      const mainStore = useMainStore();
      return { mainStore };
    },
    computed: {
      translations() {
        return this.mainStore.translations;
      },
    },
    methods: {
      async register() {
        const { orgUrl, userName, userEmail } = this.mainStore;
        const payload = {
          name: userName,
          email: userEmail,
          password: this.password,
          org_domain_name: orgUrl
        };
  
        try {
          const response = await api.post('/api/user/register/', payload);
          alert(this.translations.registration_success || 'Registration successful!');
          console.log('Response:', response.data);
          const token = response.data.token_key;
          this.mainStore.login(token);
          this.$router.push({ name: 'main-app' });
        } catch (error) {
          console.error('Failed to register:', error);
          if (error.response) {
            console.error('Error data:', error.response.data);
            console.error('Error status:', error.response.status);
            console.error('Error headers:', error.response.headers);
          } else if (error.request) {
            console.error('No response received:', error.request);
          } else {
            console.error('Error message:', error.message);
          }
          alert(this.translations.registration_failed || 'Failed to register. Check the console for more details.');
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .register-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .register-page h2 {
    margin-bottom: 20px;
  }
  
  .register-page form {
    display: flex;
    flex-direction: column;
  }
  
  .register-page form div {
    margin-bottom: 10px;
  }
  
  .register-page label {
    margin-right: 10px;
  }
  
  .register-page input {
    padding: 5px;
    font-size: 1rem;
  }
  
  .register-page button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 1rem;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .register-page button:hover {
    background-color: #45a049;
  }
  </style>
  