<template>
  <div class="language-selection-page">
    <h2>{{ translations.select_language_for_app || "Select Language:" }}</h2>
    <div v-for="lang in availableLanguages" :key="lang" class="language-option">
      <input
        type="radio"
        :id="lang"
        :value="lang"
        v-model="selectedTextLanguage"
      />
      <label :for="lang">
        <img
          :src="`/flags/${lang}.png`"
          class="flag-icon"
          :alt="languageMap[lang]"
        />
        <span class="language-name">{{ languageMap[lang] }}</span>
      </label>
    </div>
    <button @click="confirmLanguage" class="confirm-button">
      Confirm
    </button>
  </div>
</template>

<script>
import { useMainStore } from "@/stores/mainStore";
import { loadLanguageFile } from "@/localization";

export default {
  setup() {
    const mainStore = useMainStore();
    const availableLanguages = mainStore.availableLanguages;
    const languageMap = mainStore.languageMap;
    return { availableLanguages, languageMap, mainStore };
  },
  data() {
    return {
      selectedTextLanguage: "en",
    };
  },
  computed: {
    // Computed property to get translations from the store
    translations() {
      return this.mainStore.translations;
    },
  },
  methods: {
    // Method to confirm the selected language
    async confirmLanguage() {
      // Load the selected language file
      const translations = await loadLanguageFile(this.selectedTextLanguage);
      // Update the translations in the store
      this.mainStore.updateTranslations(translations);
      // Set the current language in the store
      this.mainStore.setCurrentLanguage(this.selectedTextLanguage);
      // Store the selected language in localStorage
      localStorage.setItem('userLanguage', this.selectedTextLanguage);
      // Navigate to the authentication selection page
      this.$router.push({ name: 'auth-selection' });
    },
  },
};
</script>

<style scoped>
.language-selection-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.language-selection-page h2 {
  margin-bottom: 20px;
}

.language-option {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.language-option input {
  margin-right: 10px;
}

.language-option label {
  display: flex;
  align-items: center;
}

.flag-icon {
  width: 30px;
  height: 20px;
  margin-right: 10px;
}

.language-name {
  font-size: 16px;
}

.confirm-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
}
</style>
