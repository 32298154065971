<template>
  <div class="login-page">
    <h2>{{ translations.login || "Login" }}</h2>
    <form @submit.prevent="login">
      <div>
        <label for="email">{{ translations.email || "Email" }}:</label>
        <input type="email" id="email" v-model="mainStore.userEmail" required />
      </div>
      <div>
        <label for="password">{{ translations.password || "Password" }}:</label>
        <input type="password" id="password" v-model="password" required />
      </div>
      <button type="submit">{{ translations.login || "Login" }}</button>
    </form>
  </div>
</template>

<script>
import { useMainStore } from "@/stores/mainStore";
import api from "@/endpoints/api";

export default {
  data() {
    return {
      password: ''
    };
  },
  setup() {
    const mainStore = useMainStore();
    return { mainStore };
  },
  computed: {
    translations() {
      return this.mainStore.translations;
    },
  },
  methods: {
    async login() {
      const { orgUrl, userEmail } = this.mainStore;
      const payload = {
        email: userEmail,
        password: this.password,
        org_domain_name: orgUrl
      };

      try {
        const response = await api.post('/api/user/login/', payload);
        const token = response.data.token_key;
        alert(this.translations.login_success || 'Login successful!');
        console.log('Token:', token);
        this.mainStore.login(token);
        this.$router.push({ name: 'main-app' });
      } catch (error) {
        console.error('Failed to login:', error);
        if (error.response) {
          console.error('Error data:', error.response.data);
          console.error('Error status:', error.response.status);
          console.error('Error headers:', error.response.headers);
        } else if (error.request) {
          console.error('No response received:', error.request);
        } else {
          console.error('Error message:', error.message);
        }
        alert(this.translations.login_failed || 'Failed to login. Check the console for more details.');
      }
    }
  }
};
</script>

<style scoped>
.login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.login-page h2 {
  margin-bottom: 20px;
}

.login-page form {
  display: flex;
  flex-direction: column;
}

.login-page form div {
  margin-bottom: 10px;
}

.login-page label {
  margin-right: 10px;
}

.login-page input {
  padding: 5px;
  font-size: 1rem;
}

.login-page button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.login-page button:hover {
  background-color: #45a049;
}
</style>
