import api from "@/endpoints/api";

// Function to get a signed download URL from the server
export async function s3Download(fileKey) {
    try {
        // Request a signed download URL from the API
        const response = await api.get("/api/media/signed-download-url/", {
            params: { file_key: fileKey }
        });
        const downloadURL = response.data.downloadURL;

        return downloadURL;
    } catch (err) {
        console.error("Error fetching download URL: ", err);
    }
}
