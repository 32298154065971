import L from "leaflet";
import { useMainStore } from "@/stores/mainStore";
import { nextTick } from 'vue';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: "https://unpkg.com/leaflet@1.9.4/dist/images/marker-icon-2x.png",
  iconUrl: "https://unpkg.com/leaflet@1.9.4/dist/images/marker-icon.png",
  shadowUrl: "https://unpkg.com/leaflet@1.9.4/dist/images/marker-shadow.png"
});

export default {
  setup() {
    const mainStore = useMainStore();
    return { mainStore };
  },
  mounted() {
    nextTick(() => {
      this.initializeMap();
      this.setInitialLocation();
    });
  },
  watch: {
    'mainStore.pois': {
      handler() {
        this.updateMarkers();
      },
      deep: true,
    },
  },
  methods: {
    initializeMap(center = [44.647067, -63.575619], zoom = 13) {
      const mapId = "map";
      if (!document.getElementById(mapId)) {
        console.error("Map container not found");
        return;
      }
      this.mainStore.map = L.map(mapId).setView(center, zoom);
      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attribution: "© OpenStreetMap contributors",
      }).addTo(this.mainStore.map);
    },
    setInitialLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const lat = position.coords.latitude;
            const lng = position.coords.longitude;
            this.mainStore.map.setView([lat, lng], 13);
            this.addPoiWithLocation(lat, lng);
          },
          (error) => {
            console.error("Error getting location: ", error);
            this.promptManualLocationSelection();
          }
        );
      } else {
        this.promptManualLocationSelection();
      }
    },
    promptManualLocationSelection() {
      alert(this.mainStore.translations.manual_location_prompt);
      this.addPoiWithLocation(44.647067, -63.575619);
    },
    addPoiWithLocation(lat, lng) {
      this.mainStore.pois.push({
        map_content: false,
        name: "",
        latitude: lat,
        longitude: lng,
        sub_categories: [],
        content_block: [],
        selectedCard: null,
        contentBlock: {
          oneRT: "",
          twoRT1: "",
          twoRT2: "",
        },
      });
    },
    updateMarkers() {
      if (!this.mainStore.map) {
        console.error("Map not initialized");
        return;
      }

      this.mainStore.markers.forEach((marker) => this.mainStore.map.removeLayer(marker));
      this.mainStore.markers = [];

      this.mainStore.pois.forEach((poi) => {
        if (poi.latitude && poi.longitude) {
          const marker = L.marker([poi.latitude, poi.longitude], {
            draggable: true,
          }).addTo(this.mainStore.map);
          marker.bindPopup(`<b>${poi.name}</b><br>${poi.description}`).openPopup();
          marker.on("dragend", (event) => {
            const { lat, lng } = event.target.getLatLng();
            poi.latitude = lat;
            poi.longitude = lng;
          });
          this.mainStore.markers.push(marker);
        }
      });
    },
  },
};
