<template>
  <!-- Swiper container to display layout cards -->
  <div class="swiper-container" v-if="layouts.length">
    <swiper :options="swiperOptions" @slideChange="onSlideChange">
      <!-- Swiper slide for each layout -->
      <swiper-slide v-for="layout in layouts" :key="layout.id">
        <div
          class="card"
          :class="{ selected: layout.id === selectedLayout?.id }"
          @click="selectLayout(layout)"
        >
          <h3>{{ layout.name }}</h3>
          <!-- Icons representing the fields of the layout -->
          <div class="icons">
            <font-awesome-icon 
              v-for="field in layout.fields" 
              :key="field.id" 
              :icon="getIcon(field.type)" 
              class="icon" 
            />
          </div>
        </div>
      </swiper-slide>
    </swiper>
    <div class="swiper-pagination"></div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/swiper-bundle.css';
import { useMainStore } from '@/stores/mainStore';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faKeyboard, faCamera, faImage, faMicrophone, faQuestion } from '@fortawesome/free-solid-svg-icons';

// Add font-awesome icons to the library
library.add(faKeyboard, faCamera, faImage, faMicrophone, faQuestion);

export default {
  components: {
    Swiper,
    SwiperSlide,
    FontAwesomeIcon
  },
  setup() {
    const mainStore = useMainStore();
    return { mainStore };
  },
  data() {
    return {
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination',
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      selectedLayout: null, // Currently selected layout
    };
  },
  computed: {
    layouts() {
      return this.mainStore.layouts; // Layouts from the store
    }
  },
  methods: {
    // Handle slide change event
    onSlideChange(swiper) {
      this.selectedLayout = this.layouts[swiper.activeIndex];
      this.$emit('layoutSelected', this.selectedLayout);
    },
    // Handle layout selection
    selectLayout(layout) {
      this.selectedLayout = layout;
      this.$emit('layoutSelected', layout);
    },
    // Get icon based on the field type
    getIcon(type) {
      switch (type) {
        case 'richtext':
          return 'keyboard';
        case 'image':
          return 'camera';
        case 'audio':
          return 'microphone';
        default:
          return 'question';
      }
    }
  }
};
</script>

<style>
/* Styling for swiper container */
.swiper-container {
  margin-top: 10%;
  width: 100%;
  height: 200px;
}

/* Styling for each card */
.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.2s;
}

.card:hover {
  transform: scale(1.05); /* Scale up on hover */
}

.card.selected {
  border: 2px solid #007bff; /* Highlight selected card */
}

/* Styling for icons container */
.icons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

/* Styling for each icon */
.icon {
  font-size: 2em; 
}
</style>
