import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import pinia from './stores';
import 'swiper/swiper-bundle.css';
import { useMainStore } from './stores/mainStore';

// Create the Vue application instance
const app = createApp(App);

// Use Pinia for state management
app.use(pinia);

// Use Vue Router for routing
app.use(router);

const mainStore = useMainStore();
const storedLanguage = localStorage.getItem('userLanguage') || 'en';
mainStore.setCurrentLanguage(storedLanguage).then(() => {
  app.mount('#app');
});
