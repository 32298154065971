<template>
    <form>
      <h2>{{ translations.select_languages }}</h2>
      <div v-for="lang in availableLanguages" :key="lang">
        <input type="checkbox" :id="lang" :value="lang" v-model="selectedLanguages" />
        <label :for="lang">{{ translations[lang] || languageMap[lang] }}</label>
      </div>
    </form>
  </template>
  
  <script>
  import { useMainStore } from "@/stores/mainStore";
  
  export default {
    setup() {
      const mainStore = useMainStore();
      return { mainStore };
    },
    computed: {
      translations() {
        return this.mainStore.translations;
      },
      availableLanguages() {
        return this.mainStore.availableLanguages;
      },
      selectedLanguages: {
        get() {
          return this.mainStore.selectedLanguages;
        },
        set(value) {
          this.mainStore.updateSelectedLanguages(value);
        },
      },
      languageMap() {
        return this.mainStore.languageMap;
      },
    },
  };
  </script>
  