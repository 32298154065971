<template>
  <div>
    <UserInfoForm />
    <LanguageSelection />
    <TagsForm />
    <PoiForm />
    <button @click="submitAll">{{ translations.submit }}</button>
    <button @click="goToMapsList">{{ translations.view_maps }}</button>
  </div>
</template>

<script>
import { useMainStore } from "@/stores/mainStore";
import { loadLanguageFile } from "@/localization";
import UserInfoForm from "@/components/UserInfoForm.vue";
import LanguageSelection from "@/components/LanguageSelection.vue";
import TagsForm from "@/components/TagsForm.vue";
import PoiForm from "@/components/PoiForm.vue";
import api from "@/endpoints/api";

export default {
  components: { UserInfoForm, LanguageSelection, TagsForm, PoiForm },
  setup() {
    const mainStore = useMainStore();
    return { mainStore };
  },
  computed: {
    translations() {
      return this.mainStore.translations;
    },
  },
  created() {
    const lang = this.$route.query.lang || this.mainStore.currentLanguage;
    this.loadTranslations(lang);
  },
  methods: {
    async loadTranslations(lang) {
      const translations = await loadLanguageFile(lang);
      this.mainStore.updateTranslations(translations);
    },
    async submitAll() {
      this.mainStore.updateUserName(this.mainStore.userName);
      this.mainStore.updateUserEmail(this.mainStore.userEmail);

      // Ensure coordinates are numbers and properly format the payload
      this.mainStore.payload.map_json.pois = this.mainStore.pois.map((poi) => ({
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [parseFloat(poi.longitude), parseFloat(poi.latitude)],
        },
        properties: {
          map_content: poi.map_content,
          name: poi.name,
          sub_categories: poi.sub_categories.map(Number),
          content_block: this.generateContentBlocks(poi),
        },
      }));
      this.mainStore.payload.map_json.tags = this.mainStore.tags
        .filter((tag) => tag.trim() !== "")
        .map((tag) => ({
          name: tag.trim(),
        }));

      console.log("Constructed Payload:", this.mainStore.payload);

      try {
        const response = await api.post(
          "/api/anon-map-create/",
          this.mainStore.payload
        );
        console.log("Response:", response.data);

        this.mainStore.setIdField(response.data.id_field);
        console.log("Payload with ID:", this.mainStore.payload);

        alert("Data submitted successfully!");

        localStorage.setItem(
          "submittedPayload",
          JSON.stringify(this.mainStore.payload)
        );
      } catch (error) {
        console.error("Failed to submit data:", error);
        if (error.response) {
          console.error("Error data:", error.response.data);
          console.error("Error status:", error.response.status);
          console.error("Error headers:", error.response.headers);
        } else if (error.request) {
          console.error("No response received:", error.request);
        } else {
          console.error("Error message:", error.message);
        }
        alert("Failed to submit data. Check the console for more details.");
      }
    },
    generateContentBlocks(poi) {
      const selectedLanguages = this.mainStore.selectedLanguages.length
        ? this.mainStore.selectedLanguages
        : [this.mainStore.currentLanguage];

      return selectedLanguages.map((language) => {
        const layout = this.mainStore.layouts.find(
          (layout) => layout.id === poi.selectedCard
        );
        if (!layout) {
          return [];
        }
        return {
          language,
          layout_card: parseInt(layout.id, 10),
          content_array: layout.fields.map((field) => ({
            reactive: 0,
            media_type: this.getMediaType(field.type),
            media_file:
              field.type !== "richtext"
                ? poi.contentBlock[`${language}_${field.id}`]
                : null,
            field_key: field.id,
            caption:
              field.type === "richtext"
                ? poi.contentBlock[`${language}_${field.id}`]
                : "not given",
          })),
        };
      });
    },
    getMediaType(type) {
      switch (type) {
        case "image":
          return 6;
        case "audio":
          return 7;
        case "richtext":
          return 5;
        default:
          return 0;
      }
    },
    goToMapsList() {
      this.$router.push({
        name: "maps-list",
        query: { org_domain_name: this.mainStore.orgUrl },
      });
    },
  },
  watch: {
    "$route.query.lang": "loadTranslations",
  },
};
</script>

<style>
@import "@/assets/style.css";
</style>
