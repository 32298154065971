<template>
  <div class="modal-overlay" v-if="isVisible" @click.self="close">
    <div class="modal-content">
      <button class="close-button" @click="close">X</button>
      <div>
        <h3><strong>{{ translations.poi_name || "POI Name" }}: </strong>{{ poi.properties.name }}</h3>
        <p><strong>{{ translations.categories || "Categories" }}: </strong>{{ getCategoryNames(poi.properties.sub_categories).join(", ") }}</p>
        <p><strong>{{ translations.sub_categories || "Sub Categories" }}: </strong>{{ getSubCategoryNames(poi.properties.sub_categories).join(", ") }}</p>
        <div class="content-blocks">
          <div v-for="(block, index) in poi.properties.content_block" :key="index" class="content-block">
            <p><strong>{{ translations.language || "Language" }}:</strong> {{ block.language }}</p>
            <p><strong>{{ translations.layout_card || "Layout Card" }}:</strong> {{ block.layout_card }}</p>
            <div v-for="(content, contentIndex) in block.content_array" :key="contentIndex" class="content-card">
              <p><strong>{{ translations.caption || "Caption" }}:</strong> {{ content.caption }}</p>
              <p><strong>{{ translations.media_type || "Media Type" }}:</strong> {{ getMediaType(content.media_type) }}</p>
              <div v-if="content.media_type === 6 && content.media_file" class="additional-image-container">
                <img :src="content.media_file" alt="Additional POI Image" class="additional-poi-image" />
              </div>
              <div v-if="content.media_type === 7 && content.media_file" class="additional-audio-container">
                <audio :src="content.media_file" controls></audio>
              </div>
            </div>
          </div>
        </div>
        <button class="exit-button" @click="close">{{ translations.exit || "Exit" }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { useMainStore } from "@/stores/mainStore";
import { toRaw } from "@vue/reactivity";
import { defineComponent } from "vue";
import { s3Download } from "@/utils/fileDownload";
import categoriesMixin from "@/mixins/categories";

export default defineComponent({
  name: "PoiDetail",
  mixins: [categoriesMixin],
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    poi: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useMainStore();

    // Get category names based on sub-category IDs
    const getCategoryNames = (subCategoryIds) => {
      const rawCategoryMap = toRaw(store.categoryMap);
      const rawSubCategoryIds = toRaw(subCategoryIds);
      const categories = [];

      rawSubCategoryIds.forEach((id) => {
        const categoryName =
          rawCategoryMap[id] || store.translations.unknown_category || "Unknown Category";
        if (!categories.includes(categoryName)) {
          categories.push(categoryName);
        }
      });

      return categories;
    };

    // Get sub-category names based on sub-category IDs
    const getSubCategoryNames = (subCategoryIds) => {
      const rawSubCategoryMap = toRaw(store.subCategoryMap);
      const rawSubCategoryIds = toRaw(subCategoryIds);

      return rawSubCategoryIds.map(
        (id) =>
          rawSubCategoryMap[id] ||
          `${store.translations.unknown_subcategory_id || "Unknown Subcategory ID"}: ${id}`
      );
    };

    // Close the modal
    const close = () => {
      emit("close");
    };

    // Get the media type based on its code
    const getMediaType = (type) => {
      switch (type) {
        case 6:
          return store.translations.image_card || "Image Card";
        case 7:
          return store.translations.audio_card || "Audio Card";
        case 5:
          return store.translations.text_card || "Text Card";
        default:
          return store.translations.unknown_media_type || "Unknown Media Type";
      }
    };

    return {
      getCategoryNames,
      getSubCategoryNames,
      close,
      getMediaType,
      store,
    };
  },
  data() {
    return {
      mainImage: null,
    };
  },
  async created() {
    await this.fetchCategories();
    this.fetchMediaFiles();
  },
  methods: {
    // Fetch media files and download them
    async fetchMediaFiles() {
      for (let block of this.poi.properties.content_block) {
        for (let content of block.content_array) {
          if (content.file_key) {
            try {
              const mediaFile = await s3Download(content.file_key);
              content.media_file = mediaFile;
              if (!this.mainImage && content.media_type === 6) {
                this.mainImage = mediaFile;
              }
            } catch (err) {
              console.error("Failed to download media file: ", err);
            }
          }
        }
      }
    },
  },
  computed: {
    // Get translations from the store
    translations() {
      return this.store.translations;
    },
  },
});
</script>

<style>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: auto;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 600px;
  width: 90%;
  position: relative;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-height: 90%;
  overflow-y: auto;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.exit-button {
  display: block;
  width: 100%;
  padding: 10px;
  margin-top: 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.exit-button:hover {
  background-color: #0056b3;
}

.image-container {
  text-align: center;
  margin-bottom: 20px;
}

.poi-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

.content-blocks {
  margin-top: 20px;
}

.content-block {
  margin-bottom: 20px;
}

.content-card {
  background: #f9f9f9;
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

.content-card p {
  margin: 5px 0;
}

.additional-image-container {
  text-align: center;
  margin-top: 10px;
}

.additional-poi-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

.additional-audio-container {
  text-align: center;
  margin-top: 10px;
}

.additional-audio {
  width: 100%;
}
</style>
