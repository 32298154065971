<template>
  <!-- Form for updating user information -->
  <form @submit.prevent="updateUserData" class="user-info-form">
    <h2>{{ translations.enter_user_info || "Enter User Information" }}</h2>
    <div>
      <label>{{ translations.name || "Name" }}</label>
      <input type="text" v-model="userName" :placeholder="translations.enter_name || 'Enter name'" />
      <label>{{ translations.email || "Email" }}</label>
      <input type="email" v-model="userEmail" :placeholder="translations.enter_email || 'Enter email'" />
    </div>
  </form>
</template>

<script>
import { useMainStore } from "@/stores/mainStore";

export default {
  setup() {
    const mainStore = useMainStore();
    return { mainStore };
  },
  computed: {
    // Computed property to get translations from the store
    translations() {
      return this.mainStore.translations;
    },
    // Computed property for userName with getter and setter
    userName: {
      get() {
        return this.mainStore.userName;
      },
      set(value) {
        this.mainStore.updateUserName(value);
      },
    },
    // Computed property for userEmail with getter and setter
    userEmail: {
      get() {
        return this.mainStore.userEmail;
      },
      set(value) {
        this.mainStore.updateUserEmail(value);
      },
    },
  },
  methods: {
    // Method to update user data
    updateUserData() {
      if (this.userName.trim()) {
        this.mainStore.updateUserName(this.userName.trim());
      }
      if (this.userEmail.trim()) {
        this.mainStore.updateUserEmail(this.userEmail.trim());
      }
    },
  },
};
</script>

<style scoped>
.user-info-form {
  max-width: 400px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.user-info-form h2 {
  color: #333;
  font-family: "Arial", sans-serif;
  text-align: center;
  margin-bottom: 10px;
}

.user-info-form div {
  margin-bottom: 15px;
}

.user-info-form label {
  display: block;
  margin-bottom: 5px;
}

.user-info-form input[type="text"],
.user-info-form input[type="email"] {
  width: calc(100% - 20px);
  padding: 8px;
  border: 2px solid #007bff;
  border-radius: 4px;
  margin-bottom: 10px;
}
</style>
