<template>
  <!-- Form for managing tags -->
  <form>
    <h2>{{ translations.tags || 'Tags' }}</h2>
    <!-- Loop through tags and create input fields for each tag -->
    <div v-for="(tag, index) in tags" :key="index">
      <input 
        type="text" 
        v-model="tags[index]" 
        :placeholder="translations.enter_tag || 'Enter Tag'" 
      />
      <button @click.prevent="removeTag(index)">
        {{ translations.remove || 'Remove' }}
      </button>
    </div>
    <!-- Button to add a new tag -->
    <button @click.prevent="addTag">
      {{ translations.add_tag || 'Add Tag' }}
    </button>
  </form>
</template>

<script>
import { useMainStore } from "@/stores/mainStore";

export default {
  setup() {
    const mainStore = useMainStore();
    return { mainStore };
  },
  computed: {
    // Get translations from the store
    translations() {
      return this.mainStore.translations;
    },
    // Get and set tags from the store
    tags: {
      get() {
        return this.mainStore.tags;
      },
      set(value) {
        this.mainStore.updateTags(value);
      },
    },
  },
  methods: {
    // Add a new empty tag
    addTag() {
      this.mainStore.tags.push("");
    },
    // Remove a tag at the specified index
    removeTag(index) {
      if (this.mainStore.tags.length > 1) {
        this.mainStore.tags.splice(index, 1);
      }
    },
  },
};
</script>
