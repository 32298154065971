// Function to load a language file and parse its contents into an object
export async function loadLanguageFile(language) {
  try {
    // Fetch the language file
    const response = await fetch(`/${language}.txt`);
    const text = await response.text();

    // Parse the file content into an object
    const translations = text.split('\n').reduce((acc, line) => {
      const [key, value] = line.split(':').map(item => item.trim());
      if (key && value) {
        acc[key] = value;
      }
      return acc;
    }, {});

    return translations;
  } catch (err) {
    console.error(`Error loading language file for ${language}:`, err);
  }
}
