<template>
  <div class="viewer-container">
    <div class="header">
      <button class="map-title">{{ translations.map_pois }} {{ $route.params.id }}</button>
    </div>
    <div id="map" class="map-container"></div>
    <div v-if="loading" class="loading-overlay">{{ translations.loading }}</div>
    <div v-if="error" class="error">{{ error }}</div>
    <PoiDetail 
      v-if="selectedPoi"
      :isVisible="isModalVisible" 
      :poi="selectedPoi" 
      @close="closeModal" 
    />
  </div>
</template>

<script>
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import PoiDetail from "@/components/PoiDetail.vue";
import { useMainStore } from "@/stores/mainStore";
import { useRouter, useRoute } from 'vue-router';
import api from "@/endpoints/api";

export default {
  name: 'DataViewer',
  components: {
    PoiDetail
  },
  setup() {
    const mainStore = useMainStore();
    const router = useRouter();
    const route = useRoute();
    return { mainStore, router, route };
  },
  data() {
    return {
      map: null,
      markers: [],
      loading: true,
      error: null,
      selectedPoi: null,
      isModalVisible: false,
    };
  },
  async created() {
    const mapId = this.route.params.id;
    const orgDomainName = this.route.query.org_domain_name;
    await this.fetchMapData(mapId, orgDomainName);
  },
  methods: {
    async fetchMapData(mapId, orgDomainName) {
      this.loading = true;
      this.error = null;
      try {
        const response = await api.get(`/api/maps/${mapId}/`, {
          params: { org_domain_name: orgDomainName }
        });
        console.log('Response Data:', response.data);

        const mapData = response.data.map_json;
        if (mapData && mapData.center && mapData.center.coordinates) {
          this.mainStore.setMaps(mapData);
          this.mainStore.pois = mapData.pois || [];
          console.log("Parsed Map Data:", this.mainStore.maps);

          this.initializeMap(mapData.center.coordinates);
          this.addMarkers(this.mainStore.pois);

          this.loading = false;
        } else {
          console.error("Center geometry is missing or malformed");
          this.error = this.translations.center_geometry_missing;
          this.loading = false;
        }
      } catch (error) {
        console.error("Failed to fetch items:", error);
        this.error = this.translations.fetch_failed;
        this.loading = false;
      }
    },
    initializeMap(center) {
      this.map = L.map('map').setView([center[1], center[0]], 13);
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '© OpenStreetMap contributors'
      }).addTo(this.map);
    },
    addMarkers(pois) {
      pois.forEach(poi => {
        const latLng = this.getLatLng(poi.geometry);
        if (latLng) {
          const marker = L.marker(latLng).addTo(this.map);
          marker.on('click', () => this.showModal(poi));
          this.markers.push(marker);
        } else {
          console.warn(`Invalid coordinates for POI: ${JSON.stringify(poi)}`);
        }
      });
    },
    getLatLng(geometry) {
      if (geometry && geometry.coordinates && geometry.coordinates.length === 2) {
        return [geometry.coordinates[1], geometry.coordinates[0]];
      }
      return null;
    },
    showModal(poi) {
      this.selectedPoi = poi;
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
      this.selectedPoi = null;
    }
  },
  computed: {
    translations() {
      return this.mainStore.translations;
    }
  }
};
</script>

<style>
html, body, #app {
  height: 100%;
  margin: 0;
  padding: 0;
}

.viewer-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1000;
}

.map-title {
  background-color: white;
  color: black;
  border: 2px solid black;
  padding: 15px 30px;
  font-size: 18px;
  border-radius: 5px;
  cursor: pointer;
}

.map-title:hover {
  background-color: #f0f0f0;
}

.map-container {
  flex-grow: 1;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
  font-size: 24px;
}

.error {
  color: red;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 600px;
  width: 90%;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}
</style>
