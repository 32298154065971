import { createRouter, createWebHistory } from 'vue-router';
import LanguageSelectionModel from './components/LanguageSelectionModel.vue';
import MainApp from './views/MainApp.vue';
import MapsList from './views/MapsList.vue';
import DataViewer from './views/DataViewer.vue';
import LandingPage from './views/LandingPage.vue';
import AuthSelection from './components/AuthSelection.vue';
import RegisterPage from './views/RegisterPage.vue';
import LoginPage from './views/LoginPage.vue';
import LogoutPage from './views/LogoutPage.vue';

// Define route configurations
const routes = [
  {
    path: '/',
    name: 'landing-page',
    component: LandingPage,
  },
  {
    path: '/language-selection',
    name: 'language-selection',
    component: LanguageSelectionModel,
  },
  {
    path: '/auth-selection',
    name: 'auth-selection',
    component: AuthSelection,
  },
  {
    path: '/register',
    name: 'register-page',
    component: RegisterPage,
  },
  {
    path: '/login',
    name: 'login-page',
    component: LoginPage,
  },
  {
    path: '/logout',
    name: 'logout-page',
    component: LogoutPage,
  },
  {
    path: '/app',
    name: 'main-app',
    component: MainApp,
  },
  {
    path: '/maps',
    name: 'maps-list',
    component: MapsList,
  },
  {
    path: '/view/:id',
    name: 'data-viewer',
    component: DataViewer,
  },
];

// Create and configure the router
const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
