<template>
    <div class="maps-list-container">
      <h1>{{ translations.available_maps }}</h1>
      <div v-if="loading">{{ translations.loading }}</div>
      <div v-if="error" class="error">{{ error }}</div>
      <ul v-if="!loading && !error">
        <li v-for="map in maps" :key="map.id" @click="navigateToViewer(map.id)">
          {{ translations.map_id }}: {{ map.id }} -
          <template v-if="map.center_geom && map.center_geom.coordinates && map.center_geom.coordinates.length === 2">
            {{ translations.location }}: ({{ map.center_geom.coordinates[1] }}, {{ map.center_geom.coordinates[0] }})
          </template>
          <template v-else>
            {{ translations.location_info_not_available }}
          </template>
        </li>
      </ul>
    </div>
  </template>
  
  <script>
  import { ref, computed } from 'vue';  
  import { useMainStore } from "@/stores/mainStore";
  import { useRouter } from 'vue-router';
  import api from "@/endpoints/api";
  
  export default {
    name: 'MapsList',
    setup() {
      const mainStore = useMainStore();
      const router = useRouter();
      const loading = ref(true);
      const error = ref(null);
      const maps = ref([]);
  
      const translations = computed(() => mainStore.translations);
  
      const fetchMaps = async () => {
        loading.value = true;
        error.value = null;
        try {
          const response = await api.get(`/api/maps`, {
            params: {
              org_domain_name: mainStore.orgUrl,
              filter: 'center_geom'
            }
          });
          console.log("Maps API Response:", response.data);
          if (Array.isArray(response.data)) {
            maps.value = response.data;
            console.log("Parsed Maps List:", maps.value);
          } else {
            console.error("Unexpected response structure");
            error.value = "Failed to fetch maps. Unexpected response structure.";
          }
        } catch (err) {
          console.error("Failed to fetch maps:", err);
          error.value = "Failed to fetch maps. Please try again later.";
        } finally {
          loading.value = false;
        }
      };
  
      fetchMaps();
  
      const navigateToViewer = (mapId) => {
        router.push({
          name: 'data-viewer', 
          params: { id: mapId },
          query: { org_domain_name: mainStore.orgUrl }
        });
      };
  
      return {
        loading,
        error,
        maps,
        translations,
        navigateToViewer
      };
    }
  };
  </script>
  
  <style>
  .maps-list-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .error {
    color: red;
  }
  
  ul {
    list-style: none;
    padding: 0;
  }
  
  li {
    padding: 10px;
    margin: 10px 0;
    background: #f1f2e8;
    border-radius: 5px;
    cursor: pointer;
  }
  
  li:hover {
    background: #e2e3d3;
  }
  </style>
  